import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-calculadora',
  templateUrl: './calculadora.component.html',
  styleUrls: ['./calculadora.component.css']
})
export class CalculadoraComponent implements OnInit {
  @Output() click:EventEmitter<number> = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  teclaClick(codigo:number){
    this.click.emit(codigo);
  }

}
