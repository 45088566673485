import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LiOrdenesTrabajo } from 'src/app/interfaces/liOrdenesTrabajo.interface';
import { LiPresupuestos } from 'src/app/interfaces/liPresupuestos.interface';
import { OtsService } from 'src/app/services/ots.service';
import { PresupuestosService } from 'src/app/services/presupuestos.service';
import { Respuesta } from '../../interfaces/respuesta.interface';

@Component({
  selector: 'app-historial-lineas',
  templateUrl: './historial-lineas.component.html',
  styleUrls: ['./historial-lineas.component.css']
})
export class HistorialLineasComponent implements OnInit {

  @Input() tablaBusqueda:string = 'liPresupuestosHis';
  @Input() id:number = 0;
  @Output() onRecuperar:EventEmitter<any> = new EventEmitter();
  @Output() onCancel:EventEmitter<number> = new EventEmitter();



  public presupuestos:LiPresupuestos[] = [];
  public ots:LiOrdenesTrabajo[] = [];
  public checks: number[] =[];



  constructor(
            private otsService: OtsService,
            private presupuestosService: PresupuestosService,
  ) { }

  ngOnInit(): void {
    switch (this.tablaBusqueda) {
      case 'liOrdenesTrabajoHis':
        this.cargarOrdenesTrabajo();
        break;
      case 'liPresupuestosHis':
        this.cargarPresupuestos();
        break;
    
      default:
        break;
    }
  }

  async cargarPresupuestos(){
    const resp: Respuesta = await this.presupuestosService.getLiPresupuestosHis(this.id).toPromise();
    if (resp.status != 200) {
      console.log(resp.detalle);
    }else{
      this.presupuestos = resp.detalle;
    }
  }

  async cargarOrdenesTrabajo(){
    const resp: Respuesta = await this.otsService.getLiOrdenesTrabajoHis(this.id).toPromise();
    if (resp.status != 200) {
      console.log(resp.detalle);
    }else{
      this.ots = resp.detalle;
    }
  }

  check(id: number){
    if(this.checks.includes(id)){
       const i = this.checks.indexOf(id);
       this.checks.splice(i,1);
    }
    else {
      this.checks.push(id);
    }
  }

  recuperarLineas(){
    const lineas: LiPresupuestos[] = [];
    this.checks.forEach(check => {
      switch (this.tablaBusqueda) {
        case 'liOrdenesTrabajoHis':
          lineas.push(this.ots[check]);
          break;

        case 'liPresupuestosHis':
          lineas.push(this.presupuestos[check]);
          break;
      
        default:
          break;
      }
    });
     this.onRecuperar.emit(lineas);
  }

  cancelar() {
    this.onCancel.emit(0);
  }

}
