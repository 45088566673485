import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bloqueado'
})
export class BloqueadoPipe implements PipeTransform {

  transform(value: number): any {
    if(value == 0){
      return 'NO';
    }else{
      return 'Bloqueado';
    }
  }

}
