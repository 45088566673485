import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cerrada'
})
export class CerradaPipe implements PipeTransform {

  transform(value: boolean): any {
    if(value == false){
      return 'Abierta';
    }else{
      return 'Cerrada';
    }
  }

}
