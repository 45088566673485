import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ciclo'
})
export class CicloPipe implements PipeTransform {

  transform(ciclo: number | string): string {
    let retorno: string = '';
    switch (ciclo) {
      case 1:
        retorno = 'Diario';
        break;
      case 7:
        retorno = 'Semanal';
        break;
      case 15:
        retorno = 'Quincenal';
        break;
      case 30:
        retorno = 'Mensual';
        break;
      case 90:
        retorno = 'Trimestral';
        break;
      case 120:
        retorno = 'Cuatrimestral';
        break;
      case 180:
        retorno = 'Semestral';
        break;
      case 365:
        retorno = 'Anual';
        break;
      case "1":
        retorno = 'Diario';
        break;
      case "7":
        retorno = 'Semanal';
        break;
      case "15":
        retorno = 'Quincenal';
        break;
      case "30":
        retorno = 'Mensual';
        break;
      case "90":
        retorno = 'Trimestral';
        break;
      case "120":
        retorno = 'Cuatrimestral';
        break;
      case "180":
        retorno = 'Semestral';
        break;
      case "365":
        retorno = 'Anual';
        break;
      default:
        break;
    }
    return retorno;
  }

}
