<div class="fade-in-image traerAlFrente historial">
   <div class="vScroll">
      <div class="row">
         <div class="col-lg-11"></div>

         <button (click)="cancelar();" type="button" class="btn col-lg-1">
                     
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
               <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
               </svg>
            
            </button>

   </div>
      <table *ngIf="presupuestos.length > 0" class="table table-sm table-striped caption-top table-responsive ">
         <caption>BackUp</caption>
         <thead class="clickable">
            <tr class="table-dark">
               <th></th>
               <th scope="col" colspan="1" >Código</th>
               <th scope="col" colspan="2" >Descripción</th>
               <th scope="col" class="text-end" colspan="1" >Unidades</th>
               <th scope="col" class="text-end" colspan="1" >Precio</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let presupuesto of presupuestos; let i=index"  (click)="check(i)">
               <td *ngIf="checks.includes(i)"  class="text-center"><input class="form-check-input" type="checkbox" value="" id="marcados" checked></td>
               <td *ngIf="!checks.includes(i)" class="text-center"><input class="form-check-input" type="checkbox" value="" id="marcados"></td>
               <td>{{presupuesto.codigoArticulo}}</td>
               <td colspan="2">{{presupuesto.descripcionArticulo}}</td>
               <td class="text-end">{{presupuesto.unidades}}</td>
               <td class="text-end">{{presupuesto.precio}}</td>
            </tr>
         </tbody>
      </table>
      <table *ngIf="ots.length > 0" class="table table-sm table-striped caption-top table-responsive ">
         <caption>BackUp</caption>
         <thead class="clickable">
            <tr class="table-dark">
               <th></th>
               <th scope="col" colspan="1" >Código</th>
               <th scope="col" colspan="2" >Descripción</th>
               <th scope="col" class="text-end" colspan="1" >Unidades</th>
               <th scope="col" class="text-end" colspan="1" >Precio</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let ot of ots; let i=index"  (click)="check(i)">
               <td *ngIf="checks.includes(i)"  class="text-center"><input class="form-check-input" type="checkbox" value="" id="marcados" checked></td>
               <td *ngIf="!checks.includes(i)" class="text-center"><input class="form-check-input" type="checkbox" value="" id="marcados"></td>
               <td>{{ot.codigoArticulo}}</td>
               <td colspan="2">{{ot.descripcionArticulo}}</td>
               <td class="text-end">{{ot.unidades}}</td>
               <td class="text-end">{{ot.precio}}</td>
            </tr>
         </tbody>
      </table>
      <br>
      <div class="row">
         <div class="col-5"></div>
         <div class="col-1">
            <button type="button" (click)="recuperarLineas()" class="btn btn-primary redondeado">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save-fill" viewBox="0 0 16 16">
                  <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z"/>
               </svg>
               Recuperar
            </button>
         </div>
      </div>
      <br>
   </div>
</div>