<div class="">
    <div *ngIf="monederoVisible == true" class="seccionMonedero">
        <button (click)="teclaClick(500)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur500! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur500 | number : '1.0-0'}}</div>
                <img src="assets/monedero/500.jpg" alt="" class="imagenBillete">
        </button>
        <button (click)="teclaClick(200)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur200! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur200 | number : '1.0-0'}}</div>
           <img src="assets/monedero/200.jpg" alt="" class="imagenBillete">
        </button>
        <button (click)="teclaClick(100)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur100! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur100 | number : '1.0-0'}}</div>
           <img src="assets/monedero/100.jpg" alt="" class="imagenBillete">
        </button>
        <button (click)="teclaClick(50)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur50! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur50 | number : '1.0-0'}}</div>
           <img src="assets/monedero/50.jpg" alt="" class="imagenBillete">
        </button>
        <button (click)="teclaClick(20)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur20! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur20 | number : '1.0-0'}}</div>
           <img src="assets/monedero/20.jpg" alt="" class="imagenBillete">
        </button>
        <button (click)="teclaClick(10)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur10! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur10 | number : '1.0-0'}}</div>
           <img src="assets/monedero/10.jpg" alt="" class="imagenBillete">
        </button>
        <button (click)="teclaClick(5)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur5! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur5 | number : '1.0-0'}}</div>
           <img src="assets/monedero/5.jpg" alt="" class="imagenBillete">
        </button>
        <button (click)="teclaClick(2)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur2! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur2 | number : '1.0-0'}}</div>
           <img src="assets/monedero/2.jpg" alt="" class="imagenMoneda">
        </button>
        <button (click)="teclaClick(1)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur1! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur1 | number : '1.0-0'}}</div>
           <img src="assets/monedero/1.jpg" alt="" class="imagenMoneda">
        </button>
        <button (click)="teclaClick(0.50)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur05! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur05 | number : '1.0-0'}}</div>
           <img src="assets/monedero/050.jpg" alt="" class="imagenMoneda">
        </button>
        <button (click)="teclaClick(0.20)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur02! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur02 | number : '1.0-0'}}</div>
           <img src="assets/monedero/020.jpg" alt="" class="imagenMoneda">
        </button>
        <button (click)="teclaClick(0.10)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur01! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur01 | number : '1.0-0'}}</div>
           <img src="assets/monedero/010.jpg" alt="" class="imagenMoneda">
        </button>
        <button (click)="teclaClick(0.05)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur005! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur005 | number : '1.0-0'}}</div>
           <img src="assets/monedero/005.jpg" alt="" class="imagenMoneda">
        </button>
        <button (click)="teclaClick(0.02)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur002! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur002 | number : '1.0-0'}}</div>
           <img src="assets/monedero/002.jpg" alt="" class="imagenMoneda">
        </button>
        <button (click)="teclaClick(0.01)" type="button" class="btn btn-primary botonNumeroMonedero">
           <div *ngIf="monedero.eur001! > 0" class="texto-flotante fuentePequena badge ">{{monedero.eur001 | number : '1.0-0'}}</div>
           <img src="assets/monedero/001.jpg" alt="" class="imagenMoneda">
        </button>
    </div>
    <div *ngIf="monederoVisible == false" class="seccionMonedero">
      <button (click)="teclaClick(7)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>7</strong></button>
      <button (click)="teclaClick(8)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>8</strong></button>
      <button (click)="teclaClick(9)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>9</strong></button>
      <button (click)="teclaClick(4)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>4</strong></button>
      <button (click)="teclaClick(5)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>5</strong></button>
      <button (click)="teclaClick(6)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>6</strong></button>
      <button (click)="teclaClick(1)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>1</strong></button>
      <button (click)="teclaClick(2)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>2</strong></button>
      <button (click)="teclaClick(3)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>3</strong></button>
      <button (click)="teclaClick(0)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>0</strong></button>
      <button (click)="teclaClick(10)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>.</strong></button>
      <button (click)="teclaClick(11)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>00</strong></button>
  </div>
    <div class="seccion2Monedero">
        <button (click)="teclaClick(12)" type="button" class="btn btn-primary botonFuncionMonedero"><strong>Cancel</strong></button>
        <button (click)="teclaClick(13)" type="button" class="btn btn-primary botonFuncionMonedero"><strong>C</strong></button>
        <button (click)="teclaClick(14)" type="button" class="btn btn-primary botonEnterMonedero">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
            </svg> -->
           <img src="assets/monedero/euros.jpg" alt="" class="imagenEuros">
        </button>

    </div>
</div>
