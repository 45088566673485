<div class="buscador fade-in-image traerAlFrente">
   <div class="row">
      <div class="col-lg-11"></div>

      <button (click)="cancelar();" type="button" class="btn col-lg-1">
                  
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
          
          </button>

  </div>
   <div class="vScroll">
      <div class="row mb-3">
         <div class="col-lg-8">
             <h2>Árbol de Familias (Seleccionar con botón derecho)</h2>
         </div>
     </div>
     <!-- <br>
        <div class="row">
            <div class="col-1"></div>
            <div class="col-1">
               <button (click)="expandir()" type="button" class="btn btn-primary">Expandir</button>
            </div>
            <div class="col-1">
            <button (click)="contraer()" type="button" class="btn btn-primary">Contraer</button>
            </div>
        </div> -->
     <br>
     <!-- <div *ngIf="familias.length > 0" class="container bordeContenedor arbol blanco"> -->
     <div *ngIf="familias.length > 0" class="arbol">
      <div *ngFor="let familia of familias">
         <label *ngIf="estaDesplegado(familia.padre!)" (contextmenu) = "emitir(familia)" (click)="desplegar(familia.id!)" class="clickable" [style.margin-left.px]="familia.nivel! * 20" ><strong>{{familia.descripcion}}</strong></label>
      </div>
   
     </div>
   </div>
</div>




