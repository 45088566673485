import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringTiempo'
})
export class StringTiempoPipe implements PipeTransform {

  transform(seconds: number): string {
    let horas:string = '';
    let minutos: string = '';
    let segundos: string = '';
    const hour = Math.floor(seconds / 3600);
    horas = (hour < 10)? '0' + hour : '' + hour;
    var minute = Math.floor((seconds / 60) % 60);
    minutos = (minute < 10)? '0' + minute : '' + minute;
    var second = seconds % 60;
    segundos = (second < 10)? '0' + second : '' + second;
    return horas + ':' + minutos + ':' + segundos;
  }

}
