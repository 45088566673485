import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Archivo } from 'src/app/interfaces/archivo.interface';
import { Documento } from 'src/app/interfaces/documento.interface';
import { Mensaje } from 'src/app/interfaces/mensaje.interface';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';
import { createWorker } from 'tesseract.js';

@Component({
  selector: 'app-ocr',
  templateUrl: './ocr.component.html',
  styleUrls: ['./ocr.component.css']
})
export class OcrComponent implements OnInit {

  @Output() onDni:EventEmitter<Documento> = new EventEmitter();
  @Output() onCancel:EventEmitter<number> = new EventEmitter();


  public activarSpinner:boolean = false;
  public mensaje:Mensaje={};


  // OCR
  public codigoBarras: string = "123456799";
  public apellido1: string = '';
  public apellido2: string = '';
  public nombre: string = '';

  // public imagen:string =  "assets/tmpDNI/ocr.jpg";
  public imagen:string =  '';

  constructor(
    private imagenesService: ImagenesService,
    private misFuncionesService: MisFuncionesService,
) { }

ngOnInit(): void {
  this.cargarUltimoOCR();
  //voy a poner el ocr a capón porque en teoría solo debe de haber una imágen
  // this.imagen = "assets/ocr/ocr.jpg"
  // this.doOCR();
}

cargarUltimoOCR(){
  this.imagenesService.getUltimoOCR()
                      .subscribe(
                        resp => {
                          if(resp.status != 200) {
                            this.mensaje.textoAlert = resp.detalle;
                            this.mensaje.alert = true;
                          }else {
                            this.imagen = resp.detalle.ruta;
                            this.doOCR();
                          }
                        }
                      );

    
}

async doOCR() {

this.activarSpinner = true;

const worker = createWorker({
// logger: m => console.log(m),
});
await worker.load();
// await worker.loadLanguage('eng');
await worker.loadLanguage('spa');
// await worker.initialize('eng');
await worker.initialize('spa');
// const imagen = '../../' + this.imagen;
const imagen = '/' + this.imagen;

const { data: { text } } = await worker.recognize(imagen);
// const { data: { text } } = await worker.recognize('../assets/tmpDNI/dni.jpg');
// const { data: { text } } = await worker.recognize('../assets/tmpDNI/000dni.jpg');
// const { data: { text } } = await worker.recognize('https://tesseract.projectnaptha.com/img/eng_bw.png');
// this.ocrResult = text;
// console.log(text);
this.extraerDatos(text);
await worker.terminate();
}

extraerDatosPasaporte(texto: string){
const inicioDatos: number = texto.indexOf('P<');
// console.log('índice pasaporte: ', inicioDatos);

let datos: string = texto.substring(inicioDatos, texto.length);
// datos = datos.replace('?','2');
// console.log('datos: ', datos);

let finalPrimeraLinea: number = datos.indexOf('\n');
if (finalPrimeraLinea == -1){finalPrimeraLinea = 43;}
// console.log('Final Primera Línea: ', finalPrimeraLinea);

let linea1: string = datos.substring(0, finalPrimeraLinea);

// CORRECCIONES LÍNEA 1

linea1 = this.correccionesLinea(linea1);
linea1 = linea1.replace('0', 'O');


// console.log('línea1: ', linea1);

const lineas2y3: string = datos.substring(finalPrimeraLinea + 1, datos.length);
// console.log('líneas 2 y 3: ', lineas2y3);


let finalSegundaLinea: number = lineas2y3.indexOf('\n');
if (finalSegundaLinea == -1){finalSegundaLinea = 43;}
// console.log('Final Segunda Línea: ', finalSegundaLinea);

let linea2: string = lineas2y3.substring(0, finalSegundaLinea);
// CORRECCIONES LÍNEA 2

linea2 = this.correccionesLinea(linea2);

// console.log('Línea2: ', linea2);


// console.log('Línea3: ', linea3);

// console.log('Linea1: ', linea1.length);
// console.log('Linea2: ', linea2.length);
// console.log('Linea3: ', linea3.length);

// console.log(this.misFuncionesService.left(linea3, 10));
this.formatearNombre(linea1.substring(5,linea1.length));

const dni: Documento = {
tipoDocumento: linea1.substring(0, 1),
pais: linea1.substring(2, 5),
dni: linea2.substring(linea2.indexOf('<<') - 11, linea2.indexOf('<<')),
fechaNacimiento: this.formatearFecha(linea2.substring(14, 20)),
sexo: linea2.substring(21, 22).toUpperCase(),
caducidad: this.formatearFecha(linea2.substring(8, 14)),
fechaEmision: this.formatearFecha(linea2.substring(22, 28), 10),
nacionalidad: linea2.substring(linea2.indexOf('<') - 3, linea2.indexOf('<')),
apellido1: this.apellido1,
apellido2: this.apellido2,
nombre: this.nombre

};
// console.log('Fecha Caducidad: ', linea2.substring(9, 15));

// console.log('Prueba Fecha 28/08/18', new Date('2018-08-28'));
// console.log(dni);
// this.borrarImagen();
this.onDni.emit(dni);
this.activarSpinner = false;

}

extraerDatos(texto: string){
const inicioDatos: number = texto.indexOf('IDE');
// console.log('índice DNI: ', inicioDatos);

if (inicioDatos == -1){
  this.extraerDatosPasaporte(texto);
}else{

  let datos: string = texto.substring(inicioDatos, texto.length);
  // datos = datos.replace('?','2');
  // console.log('datos: ', datos);
  
  let finalPrimeraLinea: number = datos.indexOf('\n');
  if (finalPrimeraLinea == -1){finalPrimeraLinea = 31;}
  // console.log('Final Primera Línea: ', finalPrimeraLinea);
  
  let linea1: string = datos.substring(0, finalPrimeraLinea);
  
  // CORRECCIONES LÍNEA 2
  
  linea1 = this.correccionesLinea(linea1);
  
  // console.log('línea1: ', linea1);
  
  const lineas2y3: string = datos.substring(finalPrimeraLinea + 1, datos.length);
  // console.log('líneas 2 y 3: ', lineas2y3);
  
  
  let finalSegundaLinea: number = lineas2y3.indexOf('\n');
  if (finalSegundaLinea == -1){finalSegundaLinea = 31;}
  // console.log('Final Segunda Línea: ', finalSegundaLinea);
  
  let linea2: string = lineas2y3.substring(0, finalSegundaLinea);
  // CORRECCIONES LÍNEA 2
  
  linea2 = this.correccionesLinea(linea2);
  
  // console.log('Línea2: ', linea2);
  
  let linea3: string = lineas2y3.substring(finalSegundaLinea + 1, datos.length);
  // console.log('Línea3 estimada: ', linea3);
  
  let finalTerceraLinea: number = linea3.indexOf('\n');
  if (finalTerceraLinea == -1){finalTerceraLinea = 31;}
  // console.log('Final Tercera Línea: ', finalTerceraLinea);
  linea3 = linea3.substring(0, finalTerceraLinea);
  
  // CORRECCIONES LÍNEA 3
  
  linea3 = this.correccionesLinea(linea3);
  // console.log('Línea3: ', linea3);
  
  // console.log('Linea1: ', linea1.length);
  // console.log('Linea2: ', linea2.length);
  // console.log('Linea3: ', linea3.length);
  
  // console.log(this.misFuncionesService.left(linea3, 10));
  this.formatearNombre(linea3);
  
  const dni: Documento = {
  tipoDocumento: linea1.substring(0, 3),
  pais: linea1.substring(2, 5),
  dni: linea1.substring(15, 24),
  fechaNacimiento: this.formatearFecha(linea2.substring(0, 6)),
  sexo: linea2.substring(7, 8).toUpperCase(),
  caducidad: this.formatearFecha(linea2.substring(8, 14)),
  fechaEmision: this.formatearFecha(linea2.substring(8, 14), 10),
  // nacionalidad: linea2.substring(16, 19),
  nacionalidad: linea2.substring(linea2.indexOf('<') - 3, linea2.indexOf('<')),
  apellido1: this.apellido1,
  apellido2: this.apellido2,
  nombre: this.nombre
  
  };
  // console.log('Fecha Caducidad: ', linea2.substring(9, 15));
  
  // console.log('Prueba Fecha 28/08/18', new Date('2018-08-28'));
  // console.log(dni);
  // this.borrarImagen();
  this.onDni.emit(dni);
  this.activarSpinner = false;
}

}


formatearFecha(cadena: string , restarAnos: number = 0){

// console.log('cadena: ', cadena);

const dia: string = cadena.substring(4,6);
const mes: string= cadena.substring(2,4);
let ano: number= Number(cadena.substring(0,2));
(ano < 30)? ano += 2000 : ano += 1900;
ano -= restarAnos;


// console.log('dia: ', dia);
// console.log('mes: ', mes);
// console.log('ano: ', ano);

// const fecha = new Date();
// fecha.setMonth(Number(mes) - 1);
// fecha.setDate(Number(dia));
// fecha.setFullYear(Number(ano));

// return fecha;
// return  new Date(`${ano}/${mes}/${dia}`);
// return this.misFuncionesService.formatearFecha(`${dia}/${mes}/${ano}`);
return new Date(`${ano}-${mes}-${dia}`);
// return new Date('27-05-10');

}

formatearNombre(cadena: string){
this.apellido1 = cadena.substring(0, cadena.indexOf('<'));
const tmpApellido2 = cadena.substring(cadena.indexOf('<') + 1, cadena.length);
// console.log('Apellido2: ', tmpApellido2);

this.apellido2 = tmpApellido2.substring(0, tmpApellido2.indexOf('<'));
const tmpNombre = tmpApellido2.substring(tmpApellido2.indexOf('<') + 2, tmpApellido2.length);
// console.log('tmpNombre: ', tmpNombre);

let finalNombre: number = tmpNombre.indexOf('<<');
if (finalNombre == -1){finalNombre = tmpNombre.length;}

this.nombre = tmpNombre.substring(0, finalNombre);
this.nombre = this.nombre.replace('<', ' ');

}

correccionesLinea(linea: string){
linea = linea.replace('?', '');
linea = linea.replace('.', '');
linea = linea.replace(' ', '');
linea = linea.replace('(', '<');
linea = linea.replace('$', 'S');
linea = linea.replace('mM', 'M');
return linea.toUpperCase();
}

borrarImagen(){
const archivo: Archivo = {
//  nombreArchivo: 'imagenes/prueba.jpg'
nombreArchivo: this.imagen,
}
this.misFuncionesService.borrarArchivo(archivo)
                  .subscribe(resp =>{
                    if(resp.status != 200) {
                      this.mensaje.textoAlert = resp.detalle;
                      this.mensaje.alert = true;
                    }else {
                      this.mensaje.textoSuccess = resp.detalle;
                      this.mensaje.success = true;
                    }  
                    this.activarSpinner = false;
                  });
}

cancelar() {
  this.onCancel.emit(0);
}

}

