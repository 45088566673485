import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FamiliaArbol } from 'src/app/interfaces/familiaArbol.interface';
import { Mensaje } from 'src/app/interfaces/mensaje.interface';
import { Respuesta } from 'src/app/interfaces/respuesta.interface';
import { FamiliasService } from 'src/app/services/familias.service';

@Component({
  selector: 'app-arbol-familias',
  templateUrl: './arbol-familias.component.html',
  styleUrls: ['./arbol-familias.component.css']
})
export class ArbolFamiliasComponent implements OnInit {

  @Output() onCancel:EventEmitter<number> = new EventEmitter();
  @Output() onFamilia:EventEmitter<FamiliaArbol> = new EventEmitter();

  public familiaActiva: FamiliaArbol = {};
  public familias: FamiliaArbol[] = [];
  public desplegados: number[] = [0];

  constructor(
              private familiasService: FamiliasService,
            ) { }

  async ngOnInit(): Promise<void> {
    await this.cargarFamilias();
    this.contraer();
  }

  /* -------------------------------------------------------------------------- */
  /*                                   CARGAS                                   */
  /* -------------------------------------------------------------------------- */

    async cargarFamilias(){
     this.familias = [];
     const resp: Respuesta = await this.familiasService.getArbolFamilias().toPromise();
     if (resp.status != 200){ 
      console.log(resp.detalle);
     }else{
        this.familias = resp.detalle;
     }
  }


  /* -------------------------------------------------------------------------- */
  /*                               FUNCIONES ÁRBOL                              */
  /* -------------------------------------------------------------------------- */

  async desplegar(id: number){
    if (this.desplegados.includes(Number(id))){
      const index = this.desplegados.indexOf(Number(id));
      if (index != -1 ) {this.desplegados.splice(index, 1);}
      const resp:Respuesta = await this.familiasService.getHijos(id).toPromise();
      if (resp.status == 200){
        const hijos: FamiliaArbol[] = resp.detalle;
        hijos.forEach(hijo => {
          const indexHijo = this.desplegados.indexOf(Number(hijo.id));
          if (indexHijo != -1 ) {this.desplegados.splice(indexHijo, 1);}
        });
      }
    }else{
      this.desplegados.push(Number(id));
    }
  }

  estaDesplegado(id: number){
    if (this.desplegados.includes(Number(id))){
      return true;
    }else{
      return false;
    }
  }

  async expandir(){
    this.desplegados = [0];
    const resp:Respuesta = await this.familiasService.getHijos(0).toPromise();
      if (resp.status == 200){
        const hijos: FamiliaArbol[] = resp.detalle;
        hijos.forEach(hijo => {
         this.desplegados.push(Number(hijo.id!))
        });
      }
  }

  contraer(){
    this.desplegados = [0];
  }

  emitir(familia: FamiliaArbol) {
    this.onFamilia.emit(familia);
    return false;
  }

  cancelar() {
    this.onCancel.emit(0);
  }

}
