import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Monedero } from 'src/app/interfaces/monedero.interface';

@Component({
  selector: 'app-monedero',
  templateUrl: './monedero.component.html',
  styleUrls: ['./monedero.component.css']
})
export class MonederoComponent implements OnInit {

  @Input() monedero: Monedero = {};
  @Input() monederoVisible: boolean = false;

  @Output() click:EventEmitter<number> = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  teclaClick(codigo:number){
    this.click.emit(codigo);
  }
}
