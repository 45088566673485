import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contabilizada'
})
export class ContabilizadaPipe implements PipeTransform {

  transform(value: number): any {
    if(value == 0){
      return 'No Contabilizada';
    }else{
      return 'Contabilizada';
    }
  }

}
