import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { OpcionMenu } from 'src/app/interfaces/opcionMenu.interface';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-historial-urls',
  templateUrl: './historial-urls.component.html',
  styleUrls: ['./historial-urls.component.css']
})
export class HistorialUrlsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('historial') historial!: ElementRef;
  public ventanas: OpcionMenu[] = [];
  private refreshSubscription: Subscription | undefined;

  constructor(
            private misFuncionesService: MisFuncionesService,
  ) { }
  ngOnDestroy(): void {
    // window.removeEventListener('storage', this.onStorageChange.bind(this));
    this.refreshSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.misFuncionesService.makeElementDraggable(this.historial.nativeElement);
  }

  ngOnInit(): void {
    this.cargarVentanas();
    // window.addEventListener('storage', this.onStorageChange.bind(this));
    this.refreshSubscription = this.misFuncionesService.refresh$.subscribe(() => {
      this.cargarVentanas();
    });
  }

  abrirNuevaVentana(){
    this.misFuncionesService.AbrirNuevaVentana('/ventas/tactilTickets/0/bar');
  } 

  cerrar(){
    environment.mostrarHistorialUrl = false;
  }

  cargarVentanas(){
    const storedJsonString = localStorage.getItem('ventanas');
    const storedVentanas: OpcionMenu[] = JSON.parse(storedJsonString!);
    this.ventanas = storedVentanas || [];
    
  }

  borrarVentana(item: OpcionMenu){
    const index = this.ventanas.findIndex(opcion => opcion === item);
    if (index !== -1) {
      // Elimina el elemento del arreglo
      this.ventanas.splice(index, 1);
    } else {
      // console.log('Elemento no encontrado:', item);
    }
    this.guardarVentanas();
    return false;
    
  }

  guardarVentanas(){
    localStorage.removeItem('ventanas');
    const jsonString = JSON.stringify(this.ventanas);
    localStorage.setItem('ventanas', jsonString);
  }

  abrirVentana(ruta: string){
    this.misFuncionesService.AbrirNuevaVentana(ruta);
    return false;
  }

  // onStorageChange(event: StorageEvent): void {
  //   if (event.key === 'ventanas') {
  //     this.cargarVentanas();
  //   }
  // }

  // borrarStorage(){
  //   localStorage.removeItem('ventanas');

  // }
  
}
