import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BotonFuncion } from 'src/app/interfaces/botonFuncion.interface';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';
import { TactilBotonesService } from 'src/app/services/tactil-botones.service';

@Component({
  selector: 'app-menu-rapido',
  templateUrl: './menu-rapido.component.html',
  styleUrls: ['./menu-rapido.component.css']
})
export class MenuRapidoComponent implements OnInit {

  @ViewChild('canvas') domCanvas!:ElementRef;


  public botonesFuncion: BotonFuncion[] = [];
  public funcionActiva: BotonFuncion = {};

  constructor(
              private misFuncionesService: MisFuncionesService,
              private tactilBotonesService: TactilBotonesService,
  ) { }

  ngOnInit(): void {
    this.cargarBotonesMenuRapido();
  }

  cargarBotonesMenuRapido(){
    this.tactilBotonesService.getComboMenuRapido('posicion')
                        .subscribe(
                          resp => {
                            if(resp.status != 200) {
                              console.log(resp.detalle);
                            }else {
                              this.botonesFuncion = resp.detalle;
                              if(!this.funcionActiva.id){this.funcionActiva = resp.detalle[0]};
                            }  
                          }
                        );
  }

  clickBotonFuncion(indice:number){
    this.domCanvas.nativeElement.click(); //esto cierra el canvas
    this.funcionActiva = {...this.botonesFuncion[indice]};
    this.misFuncionesService.openNewTab(`${this.funcionActiva.ruta}`);
  }

  clickBotonDFuncion(indice:number){
    this.domCanvas.nativeElement.click();
    this.funcionActiva = {...this.botonesFuncion[indice]};
    this.misFuncionesService.openNewTab(`${this.funcionActiva.rutaD}`);
  }

}
