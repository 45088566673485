import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'horaUnix'
})
export class HoraUnixPipe implements PipeTransform {

  transform(value: number): string {
   const date = new Date(value * 1000); // Convertir de segundos a milisegundos
   const hours = date.getHours().toString().padStart(2, '0');
   const minutes = date.getMinutes().toString().padStart(2, '0');
   return `${hours}:${minutes}`;
  }

}
