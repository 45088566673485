import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[mTab]'
})
export class TabDirective{

  self:any;
  nextControl:any;
  previousControl:any;

  @HostListener("keydown.enter", ["$event"])
  onEnter(event: KeyboardEvent) {
    if (this.nextControl) {
      if (this.nextControl.focus) {
        this.nextControl.focus();
        this.nextControl.select();
        event.preventDefault();
        return false;
      }else{return true;}
    }else{return true;}
  }
  
  @HostListener("keydown.arrowLeft", ["$event"])
  onLeft(event: KeyboardEvent) {
    if (this.previousControl) {
      if (this.previousControl.focus) {
        this.previousControl.focus();
        this.previousControl.select();
        event.preventDefault();
        return false;
      }else{return true;}
    }else{return true;}
  }

  constructor(private control: ElementRef) { 
    this.self=control.nativeElement;
  }
  
  

}
