import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Mensaje } from 'src/app/interfaces/mensaje.interface';
import { ImagenesService } from 'src/app/services/imagenes.service';


interface Imagen {
  id: number;
  ruta:string;
  archivo:string;
}

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})
export class GaleriaComponent implements OnInit {
  @Input() coleccion:string = 'imagenes';
  @Output() imagenClick:EventEmitter<string> = new EventEmitter();
  @Output() cerrarClick:EventEmitter<string> = new EventEmitter();

   // VARIABLES DE COMPONENTES  
   public mensaje:Mensaje={};
   public activarSpinner: boolean = false;
 
   // VARIABLES DE FORMULARIO
 
   public imagenes: Imagen[] = [];

  constructor(
    private imagenesService: ImagenesService
  ) { }

  ngOnInit(): void {
    this.cargarImagenes();
  }

  cargarImagenes(){
    this.activarSpinner = true;
    this.imagenesService.getAll(this.coleccion)
        .subscribe(resp =>{
        if (resp.status ==200) {
        
           this.imagenes = resp.detalle;
           
        }else{
           this.mensaje.textoAlert = resp.detalle;
           this.mensaje.alert = true;
        }
        this.activarSpinner = false
        });
  }

  

  rowClick(indice: number){
    const ruta = this.imagenes[indice].ruta;
    this.imagenClick.emit(ruta);
  }

  cerrar(){
    this.cerrarClick.emit();
  }

  borrarImagen(indice: number){
    this.mensaje.textoDanger = `¿Desea borrar la Imágen ${this.imagenes[indice].archivo}?`
    this.mensaje.persistente = true;
    this.mensaje.botones = true;
    this.mensaje.comandoCancelar = 'this.mensaje.danger = false;';
    this.mensaje.comandoAceptar = `this.borrarConfirmado(${this.imagenes[indice].id});`;
    this.mensaje.danger = true;
    
  }

 
  borrarConfirmado(idImagen: number){
    this.activarSpinner = true;
    this.imagenesService.borrarImagen(idImagen)
                            .subscribe(
                              resp => {
                                if(resp.status != 200) {
                                  this.mensaje.textoAlert = resp.detalle;
                                  this.mensaje.alert = true;
                                }else {
                                  this.imagenes = [];
                                  this.cargarImagenes();
                                }  
                                this.activarSpinner = false;
                              }
                            );
    
  }

  controlBotones(evento:number){
    this.mensaje.botones = false;
    this.mensaje.persistente = false;
    if (evento == 1){
      eval(this.mensaje.comandoAceptar!);
    }else{
      eval(this.mensaje.comandoCancelar!);
    }
  }

}
