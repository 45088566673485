<div class="buscadorSerie fade-in-image traerAlFrente">
   <div class="row">
      <div class="col-lg-11"></div>

      <button (click)="cancelar();" type="button" class="btn col-lg-1">
                  
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
          
          </button>

   </div>
   <h2>{{titulo}}</h2>
   <br>
   <br>
   <br>
   <div class="opcionesMovil container bordeContenedor">
      <br>
      <div *ngFor="let serie of series" class="form-check" (click)="serieActiva = serie">
         <div class="row">
            <div class="col-2">&nbsp;</div>
            <div class="col-5">
               <input *ngIf="serie.id == serieActiva.id" class="form-check-input" type="radio" name="flexRadioDefault" id= serie.id checked> 
               <input *ngIf="serie.id != serieActiva.id" class="form-check-input" type="radio" name="flexRadioDefault" id= serie.id> 
               <label class="form-check-label" for="flexRadioDefault1">
                     {{serie.descripcion}}
               </label>
            </div>
         </div>
      </div>
      <br>
  </div>
  <br>
  <br>
   <div class="row mt-2">
      <app-botonera class="botonera" [botones]="['grabar']" (grabarClick)="emitirSerie()"></app-botonera>
   </div>
</div>
