import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SeriesAlbaranesVentaService } from 'src/app/services/series-albaranes-venta.service';

interface Serie  {
  id?: number,
  serie?: string,
  descripcion?: string
}

@Component({
  selector: 'app-selector-serie',
  templateUrl: './selector-serie.component.html',
  styleUrls: ['./selector-serie.component.css']
})
export class SelectorSerieComponent implements OnInit {
  @Input() tablaBusqueda:string = 'seriesAlbaranesVenta';
  @Output() onSerie:EventEmitter<Serie> = new EventEmitter();
  @Output() onCancel:EventEmitter<number> = new EventEmitter();


  
  public titulo: string = 'Selector Serie';
  public series: Serie[] = [];
  public serieActiva: Serie = {};

  constructor(
              private seriesAlbaranesVentaService: SeriesAlbaranesVentaService,
  ) { }

  ngOnInit(): void {
    switch (this.tablaBusqueda) {
      case 'seriesAlbaranesVenta':
          this.titulo = "Selector Serie Albarán";
          this.seriesAlbaranesVentaService.getCombo('serie').subscribe(resp=>{
            if(resp.status != 200) {
              console.log(resp.detalle);
            }else {
              this.series = resp.detalle;
              this.serieActiva = this.series[0];
            }  
          });
        break;
    
    
      default:
        break;
    }
    

  }

  cancelar() {
    this.onCancel.emit(0);
  }

  emitirSerie(){
    this.onSerie.emit(this.serieActiva);
  }

}
