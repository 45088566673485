import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LiOrdenesTrabajo } from '../interfaces/liOrdenesTrabajo.interface';
import { OrdenTrabajo } from '../interfaces/ordenTrabajo.interface';

const base_url = `${ environment.base_url }/Ots`;


@Injectable({
  providedIn: 'root'
})
export class OtsService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });


  constructor(private http: HttpClient) { }

  nuevo(data:OrdenTrabajo){
    const url = `${ base_url }/postOrdenTrabajo`;
       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  grabarLineasDirectamente(data:OrdenTrabajo){
    const url = `${ base_url }/grabarLineasDirectamente`;
       
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getOrdenTrabajoIdFijo(id:number){
    const url = `${ base_url }/getOrdenTrabajoIdFijo/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getOrdenTrabajoId(id:number){
    const url = `${ base_url }/getOrdenTrabajoId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizar(data:OrdenTrabajo, id:number){
    const url = `${ base_url }/putOrdenTrabajo/${id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getOrdenNumero(numero: number, ano:number, idSerie: number){
    const url = `${ base_url }/getOrdenNumero/${numero}/${ano}/${idSerie}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarPresupuestoEnOrden(idPresupuesto: number, idOrden: number){
    const url = `${ base_url }/actualizarPresupuestoEnOrden/${idPresupuesto}/${idOrden}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarAlbaranEnOrden(idAlbaran: number, idOrden: number){
    const url = `${ base_url }/actualizarAlbaranEnOrden/${idAlbaran}/${idOrden}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarProformaEnOrden(idPresupuesto: number, idOrden: number){
    const url = `${ base_url }/actualizarProformaEnOrden/${idPresupuesto}/${idOrden}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarFacturaEnOrden(idFactura: number, idOrden: number){
    const url = `${ base_url }/actualizarFacturaEnOrden/${idFactura}/${idOrden}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarOrden(id:number){
    const url = `${ base_url }/borrarOrden/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAll(pagina:number =1, rows:number = 15, desdeNumero:number = 0, hastaNumero: number = 9999999999, desdeSerie: number = 0, hastaSerie: number = 99999999, desdeCodigoCliente:string = '', hastaCodigoCliente:string = environment.marcaFinal, desdeMatricula:string = '', hastaMatricula:string = environment.marcaFinal, desdeFecha: Date, hastaFecha: Date, abiertas: boolean = false, orden:string = 'numeroAlbaran', desc:number = 0){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&denum=${desdeNumero}&hanum=${hastaNumero}&deser=${desdeSerie}&haser=${hastaSerie}
                  &decli=${desdeCodigoCliente}&hacli=${hastaCodigoCliente}&demat=${desdeMatricula
                  }&hamat=${hastaMatricula}&defec=${desdeFecha}&hafec=${hastaFecha}&abie=${abiertas}&order=${orden}&desc=${desc}`;

    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAllLineasNoAsig(pagina:number =1, rows:number = 15, desdeNumero:number = 0, hastaNumero: number = 9999999999, desdeSerie: number = 0, hastaSerie: number = 99999999, desdeCodigoCliente:string = '', hastaCodigoCliente:string = environment.marcaFinal, desdeMatricula:string = '', hastaMatricula:string = environment.marcaFinal, desdeFecha: Date, hastaFecha: Date, abiertas: boolean = false, idEmpleado: number, orden:string = 'numeroAlbaran', desc:number = 0){
    const url = `${ base_url }/getAllLineasNoAsig?page=${pagina}&rows=${rows}&denum=${desdeNumero}&hanum=${hastaNumero}&deser=${desdeSerie}&haser=${hastaSerie}
                  &decli=${desdeCodigoCliente}&hacli=${hastaCodigoCliente}&demat=${desdeMatricula
                  }&hamat=${hastaMatricula}&defec=${desdeFecha}&hafec=${hastaFecha}&abie=${abiertas}&empl=${idEmpleado}&order=${orden}&desc=${desc}`;

    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoLiOrdenTrabajoHis(data:LiOrdenesTrabajo){
    const url = `${ environment.base_url }/HistorialLineas/postLiOrdenTrabajoHis`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, id: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getLiOrdenesTrabajoHis(id:number){
    const url = `${ environment.base_url }/HistorialLineas/getAllLiOrdenesTrabajoHis/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busqueda(valor:string, pagina:number, rows:number, desdeFecha: string, hastaFecha: string){
    const url = `${ base_url }/getBusquedaOts/${valor}/${desdeFecha}/${hastaFecha}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

}
