import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CambioParcela } from '../interfaces/CambioParcela.interface';
import { CaParcela } from '../interfaces/caParcela.interface';
import { LiParcela } from '../interfaces/liParcela.interface';
import { Parcela } from '../interfaces/parcela.interface';
import { ExtParcela } from '../interfaces/extParcela.interface';

const base_url = `${ environment.base_url }/Parcelas`;


@Injectable({
  providedIn: 'root'
})
export class ParcelasService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  })

  constructor(private http: HttpClient) { }

  nuevo(data:Parcela){
    const url = `${ base_url }/postParcela`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizar(data:Parcela){
    const url = `${ base_url }/putParcela/${data.id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  cambiarParcela(data:CambioParcela){
    const url = `${ base_url }/cambioParcela`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getParcelaCodigo(codigo:string){
    const url = `${ base_url }/getParcelaCodigo/${codigo}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getParcelaId(id:number){
    const url = `${ base_url }/getParcelaId/${id}`;
    // console.log(url);
    
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busqueda(valor:string, pagina:number, rows:number){
    const url = `${ base_url }/getBusquedaParcela/${valor}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAll(pagina:number, rows:number, desdeCodigo:string, hastaCodigo:string, desdeDescripcion:string, hastaDescripcion:string, desdeTipo:string, hastaTipo:string, orden:string, desc:number){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&decod=${desdeCodigo}&hacod=${hastaCodigo}&dedes=${desdeDescripcion}&hades=${
                  hastaDescripcion}&detip=${desdeTipo}&hatip=${hastaTipo}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrar(id:number){
    const url = `${ base_url }/borrarParcela/${id}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getCombo(orden:string){
    const url = `${ base_url }/getAll?&order=${orden}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoCaParcela(data:CaParcela){
    const url = `${ environment.base_url }/CaParcelas/postCaParcela`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarCaParcela(data:CaParcela){
    const url = `${ environment.base_url }/CaParcelas/putCaParcela/${data.id}`;

    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getLiParcelasId(id:number){
    const url = `${ environment.base_url }/LiParcelas/getLiParcelasId/${id}`;
    // console.log(url);
    
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getCaParcelaId(id:number){
    const url = `${ environment.base_url }/CaParcelas/getCaParcelaId/${id}`;
    // console.log(url);
    
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getIdParcelasPendientesLiberar(){
    const url = `${ environment.base_url }/CaParcelas/getIdParcelasPendientesLiberar`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getIdParcelasPagadas(){
    const url = `${ environment.base_url }/CaParcelas/getIdParcelasPagadas`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getIdParcelasAutoCheck(){
    const url = `${ environment.base_url }/AutoCheck/getIdParcelasOcupadas`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getIdParcelasOcupadas(){
    const url = `${ environment.base_url }/CaParcelas/getIdParcelasOcupadas`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getParcelasOcupadas(){
    const url = `${ environment.base_url }/CaParcelas/getCaParcelasOcupadas`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getPendientesBar(){
    const url = `${ environment.base_url }/CaParcelas/getPendientesBar`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getPendientesExtras(){
    const url = `${ environment.base_url }/CaParcelas/getPendientesExtras`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getPendientesTienda(){
    const url = `${ environment.base_url }/CaParcelas/getPendientesTienda`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getPendientesTiendaId(id: number){
    const url = `${ environment.base_url }/CaParcelas/getPendientesTiendaId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getParcelasClienteFijo(){
    const url = `${ base_url }/getParcelasClienteFijo`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarCaParcela(id:number){
    const url = `${ environment.base_url }/CaParcelas/borrarCaParcela/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarLiParcelas(idParcela:number){
    const url = `${ environment.base_url }/LiParcelas/borrarLiParcelas/${idParcela}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoLiParcelas(data:LiParcela[]){
    const url = `${ environment.base_url }/LiParcelas/postLiParcelas`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getClientesXfechaEntrada(fecha:Date){
    const url = `${ environment.base_url }/CaParcelas/getClientesXfechaEntrada/${fecha}`;
    // console.log(url);
    
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoExtParcelas(data:ExtParcela[]){
    const url = `${ environment.base_url }/Parcelas/postExtParcelas`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getExtParcela(idParcela: number){
    const url = `${ environment.base_url }/Parcelas/getExtParcela/${idParcela}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarExtParcelas(idParcela:number){
    const url = `${ environment.base_url }/Parcelas/borrarExtParcelas/${idParcela}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

}
