import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = `${ environment.base_url }/SubFamilias`;


@Injectable({
  providedIn: 'root'
})
export class SubFamiliasService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(private http: HttpClient) { }

  getAll(pagina:number, rows:number, desdeCodigo:string, hastaCodigo:string, desdeCodigoFamilia:string, hastaCodigoFamilia:string, desdeDescripcion:string, hastaDescripcion:string, desdeDescripcionCorta:string, hastaDescripcionCorta:string, bloqueados: boolean, orden:string, desc:number){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&decod=${desdeCodigo}&defam=${desdeCodigoFamilia}&hafam=${hastaCodigoFamilia}&hacod=${
                  hastaCodigo}&dedes=${desdeDescripcion}&hades=${
                  hastaDescripcion}&decor=${desdeDescripcionCorta}&hacor=${hastaDescripcionCorta}&bloq=${bloqueados}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getSubFamiliaId(id:number){
    const url = `${ base_url }/getSubFamiliaId/${id}`;
    // console.log(url);
    
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getSubFamiliaCodigo(codigo:string){
    const url = `${ base_url }/getSubFamiliaCodigo/${codigo}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevo(data:string){
    const url = `${ base_url }/postSubFamilia`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizar(data:string, id:number){
    const url = `${ base_url }/putSubFamilia/${id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busqueda(valor:string, pagina:number, rows:number){
    const url = `${ base_url }/getBusquedaSubFamilia/${valor}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getCombo(orden:string, idFamilia:number = 0){
    const url = `${ base_url }/getAll?idfam=${idFamilia}&order=${orden}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

}
