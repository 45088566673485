import { Component, Input, OnInit } from '@angular/core';
import { Imagen } from 'src/app/interfaces/imagen.interface';
import { Mensaje } from 'src/app/interfaces/mensaje.interface';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';

@Component({
  selector: 'app-carrusel',
  templateUrl: './carrusel.component.html',
  styleUrls: ['./carrusel.component.css']
})
export class CarruselComponent implements OnInit {

  @Input() imagenes:Imagen[] = [];


  public activarSpinner:boolean = false;
  public mensaje:Mensaje={};


  //CARRUSEL
  public mostrarFotoGrande: boolean = false;
  public fotoGrande: Imagen = {};
  public imagenesCarrusel: Imagen[] = [];
  public imagenCarrusel: Imagen = {};

  constructor(
              private imagenesService: ImagenesService,
              private misFuncionesService: MisFuncionesService,
  ) { }

  ngOnInit(): void {
    this.cargarImagenes();
  }

  cargarImagenes(){
      this.imagenesCarrusel = [...this.imagenes];
      //Como para que funcione el carrusel tengo que definir una foto activa. Escojo la primera y la quito del array.
      this.imagenCarrusel = this.imagenesCarrusel[0];
      this.imagenesCarrusel.splice(0,1);
  }

  cargarFotoGrande(imagen: Imagen){
    this.mostrarFotoGrande = true;
    this.fotoGrande = imagen;
  }

  cancelarImagenGrande(){
    this.mostrarFotoGrande = false;
  }

  borrarImagenGrande(id: number){
    this.mensaje.textoDanger = `¿Desea borrar la Imágen?`
    this.mensaje.persistente = true;
    this.mensaje.botones = true;
    this.mensaje.comandoCancelar = 'this.mensaje.danger = false;';
    this.mensaje.comandoAceptar = `this.borrarConfirmado(${id});`;
    this.mensaje.danger = true;
    
  }

  descargarImagen(ruta: string, nombreFichero: string){
   this.misFuncionesService.descargarImagen(ruta, nombreFichero);
  }

  
 
  borrarConfirmado(idImagen: number){
    this.activarSpinner = true;
    this.imagenesService.borrarImagen(idImagen)
                            .subscribe(
                              resp => {
                                if(resp.status != 200) {
                                  this.mensaje.textoAlert = resp.detalle;
                                  this.mensaje.alert = true;
                                }else {
                                  this.mostrarFotoGrande = false;
                                  this.imagenesCarrusel = [];
                                  // const indice: number = this.imagenesCarrusel.findIndex(imagen =>{return imagen.id == idImagen});
                                  // this.imagenesCarrusel.splice(indice, 1);
                                
                                  
                                  // const indice: number = this.imagenes.findIndex(imagen =>{return imagen.id == idImagen});
                                  
                                  // this.imagenes.splice(indice, 1);
                                  this.cargarImagenes();
                                }  
                                this.activarSpinner = false;
                              }
                            );
    
  }

  controlBotones(evento:number){
    this.mensaje.botones = false;
    this.mensaje.persistente = false;
    if (evento == 1){
      eval(this.mensaje.comandoAceptar!);
    }else{
      eval(this.mensaje.comandoCancelar!);
    }
  }
}
