import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Correo } from 'src/app/interfaces/correo.interface';
import { TabDirective } from '../directives/tab.directive';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.css']
})
export class MailComponent implements OnInit, AfterViewInit {

  @Input() correo:string = '';
  @Input() asunto:string = '';
  @Input() mensaje:string = '';
  @Output() onEnviar:EventEmitter<Correo> = new EventEmitter();
  @Output() onCancel:EventEmitter<number> = new EventEmitter();
  
    // NECESARIO PARA ACTIVAR TABULADOR
    @ViewChildren(TabDirective) inputs!: QueryList<TabDirective>;

   // FORMULARIO 
   public formulario:FormGroup = this.fb.group({
    correo: [this.correo, [Validators.required,  Validators.minLength(2), Validators.email]],
    asunto: [this.asunto, [Validators.required,  Validators.minLength(2)]],
    mensaje:[this.mensaje],
  },
  {
  },);

  constructor(
              private fb: FormBuilder,
  ) { }

   /* -------------------------------------------------------------------------- */
  /*                      NECESARIO PARA ACTIVAR TABULADOR                      */
  /* -------------------------------------------------------------------------- */

  ngAfterViewInit(): void {
    this.initTab();
    
  }
  
    initTab(){
      const controls=this.inputs.toArray();
      for (let i=0;i<controls.length-1;i++){
        controls[i].nextControl=controls[i+1].self;
      }
    }

  ngOnInit(): void {
    this.resetearCorreo();
  }

   /* -------------------------------------------------------------------------- */
  /*                                VALIDACIONES                                */
  /* -------------------------------------------------------------------------- */

  campoNoValido( campo: string){
    return (this.formulario.controls[campo].errors && this.formulario.controls[campo].touched)
  }


  enviar(){
    const correo: Correo = {
      correo: this.formulario.get('correo')!.value,
      asunto: this.formulario.get('asunto')!.value,
      mensaje: this.formulario.get('mensaje')!.value,
    }
    this.onEnviar.emit(correo);

  }

  cancelar() {
    this.onCancel.emit(0);
  }

  resetearCorreo(){
    this.formulario.reset({
      correo: this.correo,
      mensaje: this.mensaje,

    })
  }

}
