<div class="">
    <div class="seccionCalculadora">
        <button (click)="teclaClick(7)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>7</strong></button>
        <button (click)="teclaClick(8)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>8</strong></button>
        <button (click)="teclaClick(9)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>9</strong></button>
        <button (click)="teclaClick(4)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>4</strong></button>
        <button (click)="teclaClick(5)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>5</strong></button>
        <button (click)="teclaClick(6)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>6</strong></button>
        <button (click)="teclaClick(1)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>1</strong></button>
        <button (click)="teclaClick(2)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>2</strong></button>
        <button (click)="teclaClick(3)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>3</strong></button>
        <button (click)="teclaClick(0)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>0</strong></button>
        <button (click)="teclaClick(10)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>.</strong></button>
        <button (click)="teclaClick(11)" type="button" class="btn btn-primary botonNumeroCalculadora"><strong>00</strong></button>
    </div>
    <div class="seccion2Calculadora">
        <button (click)="teclaClick(12)" type="button" class="btn btn-primary botonFuncionCalculadora"><strong>Cancel</strong></button>
        <button (click)="teclaClick(13)" type="button" class="btn btn-primary botonFuncionCalculadora"><strong>C</strong></button>
        <button (click)="teclaClick(14)" type="button" class="btn btn-primary botonEnterCalculadora">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
            </svg>
        </button>

    </div>
</div>