import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefono'
})
export class TelefonoPipe implements PipeTransform {
  
 
    transform(telefono: string): string {
      if(telefono && telefono.length == 9){return `${telefono.substring(0,3)} ${telefono.substring(3,6)} ${telefono.substring(6,9)}`;}
      return telefono;
    }
  

}
