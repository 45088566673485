<div class="historial redondeado d-print-none"  #historial>
   <div class="text-end mt-2">
      <button type="button" class="btn-close" aria-label="Close" (click)="cerrar();"></button>
   </div>
   
   <!-- <div (click)="cargarVentanas()" class="nav-item dropdown">
      <a class="nav-link dropdown-toggle clickable" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Ventanas</a>
      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li *ngFor="let item of ventanas; let i = index;">
              <a class="dropdown-item" (contextmenu)="borrarVentana(item)" (click)="abrirVentana(item.ruta)">{{item.texto}}</a>
          </li>
      </ul>
   </div> -->

   <div *ngFor="let item of ventanas; let i = index;">
      <div class="row clickable linea" (contextmenu)="borrarVentana(item)" (click)="abrirVentana(item.ruta)">
         {{item.texto}}
      </div>
      <!-- <a class="dropdown-item clickable" (contextmenu)="borrarVentana(item)" (click)="abrirVentana(item.ruta)">{{item.texto}}</a> -->
  </div>
<!-- <button (click)="borrarStorage()" type="button" class="btn btn-primary btn-block text-center col-lg-2 col-md-4 mt-3">Borrar storage</button> -->

</div>
