import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Cliente } from '../interfaces/cliente.interface';



// const base_url = environment.base_url;
const base_url = `${ environment.base_url }/Clientes`;


@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });


  constructor(private http: HttpClient) { }

  getAll(pagina:number, rows:number, desdeCodigo:string, hastaCodigo:string, desdeNombreFiscal:string, hastaNombreFiscal:string, desdeNombreComercial:string, hastaNombreComercial:string, bloqueados:boolean, orden:string, desc:number){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&decli=${desdeCodigo}&hacli=${hastaCodigo}&defis=${desdeNombreFiscal}&hafis=${hastaNombreFiscal
                             }&decom=${desdeNombreComercial}&hacom=${hastaNombreComercial}&bloq=${bloqueados}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getClienteId(id:number){
    const url = `${ base_url }/getClienteId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getClienteCodigo(codigo:string){
    const url = `${ base_url }/getClienteCodigo/${codigo}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getClienteCif(cif:string){
    const url = `${ base_url }/getClienteCif/${cif}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizar(data:string, id:number){
    const url = `${ base_url }/putCliente/${id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarJson(data:Cliente){
    const url = `${ base_url }/putClienteJson/${data.id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoJson(data:Cliente){
    const url = `${ base_url }/postClienteJson`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, ultimoId: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevo(data:string){
    const url = `${ base_url }/postCliente`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, ultimoId: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busqueda(valor:string, pagina:number, rows:number){
    const url = `${ base_url }/getBusquedaCliente/${valor}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getCombo(orden:string){
    const url = `${ base_url }/getAll?&order=${orden}&bloq=false`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getNextClienteCodigo(){
    const url = `${ base_url }/getNextClienteCodigo`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  cambiarCodigoCliente(oldCodigo: string, newCodigo: string){
   const url = `${ base_url }/cambiarCodigoCliente/${oldCodigo}/${newCodigo}`;
   
   return this.http.get(url, {headers: this.headers})
                   .pipe(
                     map(
                       (resp:any)=>{
                         // console.log(resp);
                         const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                         
                         return respuesta;
                       }
                     )
                   )
                   ;
 }


}
