import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cerrado'
})
export class CerradoPipe implements PipeTransform {

  transform(value: number): any {
    if(value == 1){
      return 'Cerrado';
    }else{
      return 'Abierto';
    }
  }

}
