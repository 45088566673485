<!-- <div class="container fondoFormulario bordeContenedor"> -->
<div class="buscador fade-in-image">
    <div class="row">
        <div class="col-lg-11"></div>

        <button (click)="cancelar();" type="button" class="btn col-lg-1">
                    
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
              </svg>
            
            </button>

    </div>
    <h2>Lector Documentos</h2>
    <br>
    <div class="text-center">
        <img src="{{imagen}}" class="carnet ">
    </div>
    <div class="row">
        <div>{{imagen}}</div>
    </div>


</div>

<app-spinner *ngIf="activarSpinner"></app-spinner>
<app-mensaje [mensaje]=mensaje></app-mensaje>

