import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Articulo } from 'src/app/interfaces/articulo.interface';
import { Buscador } from 'src/app/interfaces/buscador.interface';
import { Cliente } from 'src/app/interfaces/cliente.interface';
import { DescuentoVolumen } from 'src/app/interfaces/descuentoVolumen';
import { FacturaVenta } from 'src/app/interfaces/facturaVenta.interface';
import { Familia } from 'src/app/interfaces/familia.interface';
import { Parcela } from 'src/app/interfaces/parcela.interface';
import { PedidoProveedor } from 'src/app/interfaces/pedidoProveedor.interface';
import { Proveedor } from 'src/app/interfaces/proveedor.interface';
import { ReservaParcela } from 'src/app/interfaces/reservaParcela.interface';
import { SubFamilia } from 'src/app/interfaces/subFamilia.interface';
import { TarifaCompuesta } from 'src/app/interfaces/tarifaCompuesta.interface';
import { Usuario } from 'src/app/interfaces/usuario.interface';
import { Vehiculo } from 'src/app/interfaces/vehiculo.interface';
import { Veterinario } from 'src/app/interfaces/veterinario.interface';
import { ArticulosService } from 'src/app/services/articulos.service';
import { ClientesService } from 'src/app/services/clientes.service';
import { DescuentosService } from 'src/app/services/descuentos.service';
import { FacturasService } from 'src/app/services/facturas.service';
import { FamiliasService } from 'src/app/services/familias.service';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';
import { ParcelasService } from 'src/app/services/parcelas.service';
import { PedidosProveedorService } from 'src/app/services/pedidos-proveedor.service';
import { ProveedoresService } from 'src/app/services/proveedores.service';
import { ReservasParcelaService } from 'src/app/services/reservas-parcela.service';
import { SubFamiliasService } from 'src/app/services/subfamilias.service';
import { TarifasService } from 'src/app/services/tarifas.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { VehiculosService } from 'src/app/services/vehiculos.service';
import { VeterinariosService } from 'src/app/services/veterinarios.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent implements OnInit, AfterViewInit {

  @Input() tablaBusqueda:string = 'clientes';
  @Input() devolverId:boolean = false;
  @Output() onCancel:EventEmitter<number> = new EventEmitter();
  @Output() onId:EventEmitter<number> = new EventEmitter();
  @ViewChild('entrada') domEntrada!:ElementRef;

  // VARIABLES PAGINADOR
  // public paginas:number[] = [];
  public totalPaginas:number = 1;
  public paginaActiva : number = 1;

  // VARIABLES FORMULARIO
  public activarCodigosArticuloAlt: boolean = environment.activarCodigosArticuloAlt;

  
  // VARIABLES TABLA 
  public articulos: Articulo[] = [];
  public clientes: Cliente[] = [];
  public descuentosVolumen: DescuentoVolumen[]=[];
  public familias: Familia[] = [];
  public parcelas: Parcela[] = [];
  public pedidosProveedor: PedidoProveedor[] = [];
  public proveedores: Proveedor[] = [];
  public reservasParcela: ReservaParcela[] = [];
  public subFamilias: SubFamilia[] = [];
  public tarifas: TarifaCompuesta[] = [];
  public usuarios: Usuario[] = [];
  public vehiculos: Vehiculo[] = [];
  public veterinarios: Veterinario[] = [];
  public columns: string[] = ['Código','Nombre Fiscal','Nombre Comercial', 'Cif', 'teléfono', 'localidad'];
  public maxRows: number = 10;
  public rows: Buscador[] = [];
  public tickets: FacturaVenta[] = [];
  public titulo:string = 'Buscador de Clientes';
  public valoraBuscar:string = '';

  public formulario: FormGroup = this.fb.group({
    entrada:     [''],
  });

  debouncer: Subject<string> = new Subject();

  constructor(private fb: FormBuilder,
              private router: Router,
              private articulosService: ArticulosService,
              private clientesService: ClientesService,
              private descuentosService: DescuentosService,
              private facturasService: FacturasService,
              private familiasService: FamiliasService,
              private misFuncionesService: MisFuncionesService,
              private parcelasService: ParcelasService,
              private pedidosProveedorService: PedidosProveedorService,
              private proveedoresService: ProveedoresService,
              private reservasParcelaService: ReservasParcelaService,
              private subFamiliasService: SubFamiliasService,
              private tarifasService: TarifasService,
              private usuariosService: UsuariosService,
              private vehiculosService: VehiculosService,
              private veterinariosService: VeterinariosService,
    ) { }

  ngAfterViewInit(): void {
    this.domEntrada.nativeElement.focus();
  }

  ngOnInit(): void {

    switch (this.tablaBusqueda) {
      case 'articulos':
          (this.activarCodigosArticuloAlt)? this.columns = ['Código','Descripción','Desc. Corta','EAN', 'Código Alt.', 'Cod. Prov.'] : this.columns = ['Código','Descripción','Desc. Corta','EAN'];
          this.titulo = "Buscador de Artículos";
        break;
      case 'clientes':
          this.columns = ['Código','Nombre Fiscal','Nombre Comercial', 'Cif', 'Teléfono', 'Localidad', 'Dirección'];
        break;
      case 'descuentosVolumen':
          this.columns = ['Código','Nombre','Descripción'];
          this.titulo = "Buscador de Descuentos por Volumen";
        break;
      case 'familias':
          this.columns = ['Código','Descripción','Desc. Corta'];
          this.titulo = "Buscador de Familias";
        break;
      case 'parcelas':
          this.columns = ['Código','Descripción','Tipo'];
          this.titulo = "Buscador de Parcelas";
        break;
      case 'pedidos':
          this.columns = ['Número','Proveedor','Fecha'];
          this.titulo = "Buscador de Pedidos";
        break;
      case 'proveedores':
          this.columns = ['Código','Nombre Fiscal','Nombre Comercial', 'Cif'];
          this.titulo = "Buscador de Proveedores";
        break;
      case 'reservasParcela':
          this.columns = ['Nombre','Teléfono','Fecha Entrada', 'Parcela'];
          this.titulo = "Buscador de Reservas";
        break;
      case 'subFamilias':
          this.columns = ['Código','Descripción','Desc. Corta'];
          this.titulo = "Buscador de SubFamilias";
        break;
      case 'tarifas':
          this.columns = ['Código','Descripción'];
          this.titulo = "Buscador de Tarifas";
        break;
      case 'ticketsDevolucion':
          this.columns = ['Número','Fecha','F.Pago','Total'];
          this.titulo = "Buscador de Tickets";
        break;  
      case 'usuarios':
          this.columns = ['Código','nombre'];
          this.titulo = "Buscador de Usuarios";
        break;
      case 'vehiculos':
          this.columns = ['Matrícula','Modelo', 'Nombre Fiscal'];
          this.titulo = "Buscador de Vehículos";
        break;
      case 'veterinarios':
          this.columns = ['Código', 'Nombre Fiscal', 'Nombre Comercial', 'Cif', 'Teléfono'];
          this.titulo = "Buscador de Veterinarios";
        break;
    
      default:
        break;
    }
    this.debouncer
      .pipe(debounceTime(600))
      .subscribe(valor=>{
      // console.log('devouncer: ', valor);
      this.valoraBuscar = valor;
      this.totalPaginas = 1;
      this.paginaActiva = 1;
      this.consultar();
    });

    this.consultar();
    
  }

  cancelar() {
    this.onCancel.emit(0);
  }

  rowClick(indice:number){
    let ruta:string = '';
    let id: number = 0;
    this.cancelar();
    switch (this.tablaBusqueda) {
      case 'articulos':
        id = this.articulos[indice].id!;
        ruta = `/mantenimientos/manArticulos/${id}`;
        break;
      case 'clientes':
        id = this.clientes[indice].id!;
        ruta = `/mantenimientos/manClientes/${id}`;
        break;
      case 'descuentosVolumen':
        id = this.descuentosVolumen[indice].id!;
        ruta = `/ventas/descuentosVolumen/${id}`;
        break;
      case 'familias':
        id = this.familias[indice].id!;
        ruta = `/mantenimientos/manFamilias/${id}`;
        break;
      case 'parcelas':
        id = this.parcelas[indice].id!;
        ruta = `/camping/manParcelas/${id}`;
        break;
      case 'pedidos':
        id = this.pedidosProveedor[indice].id!;
        ruta = `/compras/pedidos/${id}`;
        break;
      case 'proveedores':
        id = this.proveedores[indice].id!;
        ruta = `/mantenimientos/manProveedores/${id}`;
        break;
      case 'reservasParcela':
        id = this.reservasParcela[indice].idParcela!;
        ruta = `/camping/reservaParcela/${id}`;
        break;
      case 'subFamilias':
        id = this.subFamilias[indice].id!;
        ruta = `/mantenimientos/manSubFamilias/${id}`;
        break;
      case 'tarifas':
        id = this.tarifas[indice].id!;
        ruta = `/mantenimientos/manTarifas/${id}`;
        break;
      case 'ticketsDevolucion':
        id = this.tickets[indice].id!;
        ruta = `/ventas/devoluciones/${id}`;
        break;
      case 'usuarios':
        id = this.usuarios[indice].id!;
        ruta = `/mantenimientos/manUsuarios/${id}`;
        break;
      case 'vehiculos':
        id = this.vehiculos[indice].id!;
        ruta = `/mantenimientos/manVehiculos/${id}`;
        break;
      case 'veterinarios':
        id = this.veterinarios[indice].id!;
        ruta = `/agro/manVeterinarios/${id}`;
        break;
    
      default:
        break;
    }
    if (this.devolverId){
      this.onId.emit(id);
    }else{
      this.router.navigateByUrl(ruta);
    }
  }

  teclaPresionada(event: any){
    // const valor = event.target.value;
    const valor = this.misFuncionesService.eliminarBarra(event.target.value);
    this.debouncer.next(valor);

  }

  // configurarPaginador(totalPaginas:number){
  //   let i = 0;
  //   this.paginas = [];
  //   while (i < totalPaginas) {
  //     this.paginas.push(i + 1);
  //     i++;
  //   }
  // }

  consultar(pagina:number=1){
    // console.log('Evento: ', pagina);
    
    
    this.paginaActiva = pagina;
    // console.log('Página Activa Buscador: ', this.paginaActiva);
     switch (this.tablaBusqueda) {
       case 'articulos':
         this.buscarArticulo(this.valoraBuscar);
         break;
       case 'clientes':
         this.buscarCliente(this.valoraBuscar);
         break;
       case 'descuentosVolumen':
         this.buscarDescuentoVolumen(this.valoraBuscar);
         break;
       case 'familias':
         this.buscarFamilia(this.valoraBuscar);
         break;
       case 'parcelas':
         this.buscarParcela(this.valoraBuscar);
         break;
       case 'pedidos':
         this.buscarPedido(this.valoraBuscar);
         break;
       case 'proveedores':
         this.buscarProveedor(this.valoraBuscar);
         break;
       case 'reservasParcela':
         this.buscarReservasParcela(this.valoraBuscar);
         break;
       case 'subFamilias':
         this.buscarSubFamilia(this.valoraBuscar);
         break;
       case 'tarifas':
         this.buscarTarifa(this.valoraBuscar);
         break;
       case 'ticketsDevolucion':
         this.buscarTicketDevolucion(this.valoraBuscar);
         break;
       case 'usuarios':
         this.buscarUsuario(this.valoraBuscar);
         break;
       case 'vehiculos':
         this.buscarVehiculo(this.valoraBuscar);
         break;
       case 'veterinarios':
         this.buscarVeterinario(this.valoraBuscar);
         break;
     
       default:
         break;
     }
  }


  /* -------------------------------------------------------------------------- */
  /*                                  BUSQUEDAS                                 */
  /* -------------------------------------------------------------------------- */

  buscarCliente(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.clientesService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.clientes = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.clientes.forEach(
                                      cliente =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = cliente.codigo;
                                        row.campo2 = cliente.nombreFiscal;
                                        row.campo3 = cliente.nombreComercial;
                                        row.campo4 = cliente.cif;
                                        row.campo5 = cliente.tlfPrincipal;
                                        row.campo6 = cliente.localidad;
                                        row.campo7 = cliente.direccion;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.clientesService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.clientes = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.clientes.forEach(
                                      cliente =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = cliente.codigo;
                                        row.campo2 = cliente.nombreFiscal;
                                        row.campo3 = cliente.nombreComercial;
                                        row.campo4 = cliente.cif;
                                        row.campo5 = cliente.tlfPrincipal;
                                        row.campo6 = cliente.localidad;
                                        row.campo7 = cliente.direccion;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();

  }

  buscarDescuentoVolumen(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            this.descuentosService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.descuentosVolumen = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.descuentosVolumen.forEach(
                                      descuento =>{
                                        let row: Buscador = {};
                                        row.campo1 = descuento.codigo;
                                        row.campo2 = descuento.nombre;
                                        row.campo3 = descuento.descripcionLinea;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.descuentosService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.descuentosVolumen = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.descuentosVolumen.forEach(
                                      descuento =>{
                                        let row: Buscador = {};
                                        row.campo1 = descuento.codigo;
                                        row.campo2 = descuento.nombre;
                                        row.campo3 = descuento.descripcionLinea;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarFamilia(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.familiasService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.familias = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.familias.forEach(
                                      familia =>{
                                        let row: Buscador = {};
                                        row.campo1 = familia.codigo;
                                        row.campo2 = familia.descripcion;
                                        row.campo3 = familia.descripcionCorta;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.familiasService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.familias = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.familias.forEach(
                                      familia =>{
                                        let row: Buscador = {};
                                        row.campo1 = familia.codigo;
                                        row.campo2 = familia.descripcion;
                                        row.campo3 = familia.descripcionCorta;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarParcela(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.parcelasService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.parcelas = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.parcelas.forEach(
                                      parcela =>{
                                        let row: Buscador = {};
                                        row.campo1 = parcela.codigo;
                                        row.campo2 = parcela.descripcion;
                                        row.campo3 = parcela.tipo;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.parcelasService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.parcelas = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.parcelas.forEach(
                                      parcela =>{
                                        let row: Buscador = {};
                                        row.campo1 = parcela.codigo;
                                        row.campo2 = parcela.descripcion;
                                        row.campo3 = parcela.tipo;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarPedido(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.pedidosProveedorService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.pedidosProveedor = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.pedidosProveedor.forEach(
                                      pedido =>{
                                        let row: Buscador = {};
                                        row.campo1 = pedido.numeroPedido?.toString();
                                        row.campo2 = pedido.nombreFiscal;
                                        row.campo3 = this.misFuncionesService.formatearFecha(this.misFuncionesService.fechaaString(new Date(pedido.fechaPedido!)));
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.pedidosProveedorService.getAll(this.paginaActiva, this.maxRows, 0, 999999999999,'','', this.misFuncionesService.finDeLosTiempos(), '', this.misFuncionesService.finDeLosTiempos(), 'fechaPedido', 1,1)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.pedidosProveedor = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.pedidosProveedor.forEach(
                                      pedido =>{
                                        let row: Buscador = {};
                                        row.campo1 = pedido.numeroPedido?.toString();
                                        row.campo2 = pedido.nombreFiscal;
                                        row.campo3 = this.misFuncionesService.formatearFecha(this.misFuncionesService.fechaaString(new Date(pedido.fechaPedido!)));
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarProveedor(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            this.proveedoresService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.proveedores = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.proveedores.forEach(
                                      proveedor =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = proveedor.codigo;
                                        row.campo2 = proveedor.nombreFiscal;
                                        row.campo3 = proveedor.nombreComercial;
                                        row.campo4 = proveedor.cif;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }else{
          this.proveedoresService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,true,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.proveedores = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.proveedores.forEach(
                                      proveedor =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = proveedor.codigo;
                                        row.campo2 = proveedor.nombreFiscal;
                                        row.campo3 = proveedor.nombreComercial;
                                        row.campo4 = proveedor.cif;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();

  }

  buscarReservasParcela(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            this.reservasParcelaService.busqueda(valor, this.paginaActiva, this.maxRows, 'nombre')
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.reservasParcela = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.reservasParcela.forEach(
                                      reserva =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = reserva.nombre;
                                        row.campo2 = reserva.telefono;
                                        row.campo3 = this.misFuncionesService.formatearFecha(this.misFuncionesService.fechaaString(new Date(reserva.fechaEntrada!)));
                                        row.campo4 = reserva.codigoParcela;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }else{
          this.reservasParcelaService.getAll(this.paginaActiva, this.maxRows, 'nombre',0, this.misFuncionesService.principioDeLosTiempos(), this.misFuncionesService.finDeLosTiempos())
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.reservasParcela = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.reservasParcela.forEach(
                                      reserva =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = reserva.nombre;
                                        row.campo2 = reserva.telefono;
                                        row.campo3 = this.misFuncionesService.formatearFecha(this.misFuncionesService.fechaaString(new Date(reserva.fechaEntrada!)));
                                        row.campo4 = reserva.codigoParcela;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();

  }

  buscarSubFamilia(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.subFamiliasService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.subFamilias = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;
                                    

                                    this.subFamilias.forEach(
                                      subFamilia =>{
                                        let row: Buscador = {};
                                        row.campo1 = subFamilia.codigo;
                                        row.campo2 = subFamilia.descripcion;
                                        row.campo3 = subFamilia.descripcionCorta;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.subFamiliasService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.subFamilias = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.subFamilias.forEach(
                                      subFamilia =>{
                                        let row: Buscador = {};
                                        row.campo1 = subFamilia.codigo;
                                        row.campo2 = subFamilia.descripcion;
                                        row.campo3 = subFamilia.descripcionCorta;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarTarifa(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.tarifasService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.tarifas = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;
                                    

                                    this.tarifas.forEach(
                                      tarifa =>{
                                        let row: Buscador = {};
                                        row.campo1 = tarifa.codigo;
                                        row.campo2 = tarifa.descripcion;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.tarifasService.getAllTarifaCompuesta(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.tarifas = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.tarifas.forEach(
                                      tarifa =>{
                                        let row: Buscador = {};
                                        row.campo1 = tarifa.codigo;
                                        row.campo2 = tarifa.descripcion;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarTicketDevolucion(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.facturasService.busquedaTicket(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.tickets = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;
                                    

                                    this.tickets.forEach(
                                      ticket =>{
                                        let row: Buscador = {};
                                        row.campo1 = ticket.numeroFactura + '/' + ticket.serie;
                                        row.campo2 = ticket.fechaFactura?.toString();
                                        row.campo3 = ticket.descriFormaPago;
                                        row.campo4 = this.misFuncionesService.round(ticket.totalFactura!, 2).toFixed(2);
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.facturasService.getAllTickets(this.paginaActiva, this.maxRows, this.misFuncionesService.sumarDiasFecha(this.misFuncionesService.hoy(), -15), this.misFuncionesService.hoy())
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.tickets = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.tickets.forEach(
                                      ticket =>{
                                        let row: Buscador = {};
                                        row.campo1 = ticket.numeroFactura + '/' + ticket.serie;
                                        row.campo2 = ticket.fechaFactura?.toString();
                                        row.campo3 = ticket.descriFormaPago;
                                        row.campo4 = this.misFuncionesService.round(ticket.totalFactura!, 2).toFixed(2);
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarArticulo(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            if (this.paginaActiva > this.totalPaginas){this.paginaActiva = this.totalPaginas;}
                this.articulosService.busqueda(valor, this.paginaActiva, this.maxRows)
                .subscribe(
                  resp => {
                    if (resp.status == 200) {
                        this.articulos = resp.detalle;
                        // this.configurarPaginador(resp.totalPaginas);
                        this.totalPaginas = resp.totalPaginas;
  
                        this.articulos.forEach(
                          articulo =>{
                            let row: Buscador = {};
                            row.campo1 = articulo.codigo;
                            row.campo2 = (environment.precioBuscadorArticulos)?`${articulo.descripcion} (${articulo.pvp}€)`: articulo.descripcion;
                            row.campo3 = articulo.descripcionCorta;
                            row.campo4 = articulo.codigoBarras;
                            row.campo5 = articulo.codigoArtProveedor;
                            row.campo6 = articulo.codigoAlt;
                            this.rows.push(row);
                          }
                        )
                    }
                  }
                );
                            
        }else{
          this.articulosService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.articulos = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.articulos.forEach(
                                      articulo =>{
                                        let row: Buscador = {};
                                        row.campo1 = articulo.codigo;
                                        row.campo2 = articulo.descripcion;
                                        row.campo3 = articulo.descripcionCorta;
                                        row.campo4 = articulo.codigoBarras;
                                        row.campo5 = articulo.codigoArtProveedor;
                                        row.campo6 = articulo.codigoAlt;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarUsuario(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            if (this.paginaActiva > this.totalPaginas){this.paginaActiva = this.totalPaginas;}
            this.usuariosService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.usuarios = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.usuarios.forEach(
                                      usuario =>{
                                        let row: Buscador = {};
                                        row.campo1 = usuario.codigo;
                                        row.campo2 = usuario.nombre;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.usuariosService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'nombre',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.usuarios = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.usuarios.forEach(
                                      usuario =>{
                                        let row: Buscador = {};
                                        row.campo1 = usuario.codigo;
                                        row.campo2 = usuario.nombre;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarVehiculo(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            if (this.paginaActiva > this.totalPaginas){this.paginaActiva = this.totalPaginas;}
            this.vehiculosService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.vehiculos = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.vehiculos.forEach(
                                      vehiculo =>{
                                        let row: Buscador = {};
                                        row.campo1 = vehiculo.codigo;
                                        row.campo2 = vehiculo.modelo;
                                        row.campo3 = vehiculo.nombreFiscal;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.vehiculosService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'matricula',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.vehiculos = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.vehiculos.forEach(
                                      vehiculo =>{
                                        let row: Buscador = {};
                                        row.campo1 = vehiculo.codigo;
                                        row.campo2 = vehiculo.modelo;
                                        row.campo3 = vehiculo.nombreFiscal;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarVeterinario(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            if (this.paginaActiva > this.totalPaginas){this.paginaActiva = this.totalPaginas;}
            this.veterinariosService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.veterinarios = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.veterinarios.forEach(
                                      veterinario =>{
                                        let row: Buscador = {};
                                        row.campo1 = veterinario.codigo;
                                        row.campo2 = veterinario.nombreFiscal;
                                        row.campo3 = veterinario.nombreComercial;
                                        row.campo4 = veterinario.cif;
                                        row.campo5 = veterinario.tlfPrincipal;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.veterinariosService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.veterinarios = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.veterinarios.forEach(
                                      veterinario =>{
                                        let row: Buscador = {};
                                        row.campo1 = veterinario.codigo;
                                        row.campo2 = veterinario.nombreFiscal;
                                        row.campo3 = veterinario.nombreComercial;
                                        row.campo4 = veterinario.cif;
                                        row.campo5 = veterinario.tlfPrincipal;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

}
